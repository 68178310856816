/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-page-header-index-pageHeader {
  background: #fff;
  padding: 16px 32px 0 32px;
  border-bottom: 1px solid #e8e8e8;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-wide {
  max-width: 1200px;
  margin: auto;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-detail {
  display: flex;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-row {
  display: flex;
  width: 100%;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-breadcrumb {
  margin-bottom: 16px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-tabs {
  margin: 0 0 0 -8px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-tabs .ant-tabs-bar {
  border-bottom: none;
  margin-bottom: 1px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-logo {
  flex: 0 1 auto;
  margin-right: 16px;
  padding-top: 1px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-logo > img {
  width: 28px;
  height: 28px;
  border-radius: 4px;
  display: block;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-title {
  font-size: 20px;
  font-weight: 500;
  color: rgba(0, 0, 0, 0.85);
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action {
  margin-left: 56px;
  min-width: 266px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group:not(:last-child),
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn:not(:last-child) {
  margin-right: 8px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group > .ant-btn {
  margin-right: 0;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-title,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-content {
  flex: auto;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-main {
  flex: 0 1 auto;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-main {
  width: 100%;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-title,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action {
  margin-bottom: 16px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-logo,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-content,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
  margin-bottom: 16px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action,
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
  text-align: right;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
  margin-left: 88px;
  min-width: 242px;
}
@media screen and (max-width: 1200px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
    margin-left: 44px;
  }
}
@media screen and (max-width: 992px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
    margin-left: 20px;
  }
}
@media screen and (max-width: 768px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-row {
    display: block;
  }
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action,
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-extraContent {
    margin-left: 0;
    text-align: left;
  }
}
@media screen and (max-width: 576px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-detail {
    display: block;
  }
}
@media screen and (max-width: 480px) {
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group,
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn {
    display: block;
    margin-bottom: 8px;
  }
  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-action .ant-btn-group > .ant-btn {
    display: inline-block;
    margin-bottom: 0;
  }
}

.antd-pro-components-page-header-wrapper-grid-content-main {
  width: 100%;
  height: 100%;
  min-height: 100%;
  transition: 0.3s;
}
.antd-pro-components-page-header-wrapper-grid-content-main.antd-pro-components-page-header-wrapper-grid-content-wide {
  max-width: 1200px;
  margin: 0 auto;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-page-header-wrapper-index-content {
  margin: 24px 24px 0;
}
@media screen and (max-width: 576px) {
  .antd-pro-components-page-header-wrapper-index-content {
    margin: 24px 0 0;
  }
}

.antd-pro-containers-users-all-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-users-all-styles-btn {
  margin-right: 0.4em;
}

.antd-pro-containers-journals-all-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-journals-all-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-journals-all-styles-img {
  width: 80px;
  height: 80px;
}

.antd-pro-containers-email-views-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-email-views-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-email-views-styles-img {
  width: 80px;
  height: 80px;
}
.antd-pro-containers-email-views-styles-filterData > div > div {
  justify-content: space-between !important;
  width: 300px;
}
.antd-pro-containers-email-views-styles-filterData > div > div > div {
  text-align: left;
}

.antd-pro-containers-news-all-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-news-all-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-news-all-styles-img {
  width: 80px;
  height: 80px;
}

.antd-pro-containers-news-letter-all-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-news-letter-all-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-news-letter-all-styles-img {
  width: 80px;
  height: 80px;
}

.antd-pro-containers-subscription-all-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-subscription-all-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-subscription-all-styles-img {
  width: 80px;
  height: 80px;
}
.antd-pro-containers-subscription-all-styles-flexRow {
  display: flex;
  justify-content: space-between;
}
.antd-pro-containers-subscription-all-styles-filterDiv > div {
  display: inline-flex !important;
}
.antd-pro-containers-subscription-all-styles-filterDiv > div > div {
  /* display: inline-flex !important;
    justify-content: space-between !important;
    width: 400px; */
  width: 400px;
}
.antd-pro-containers-subscription-all-styles-priceTable tr td,
.antd-pro-containers-subscription-all-styles-priceTable tr th {
  padding: 5px 10px;
}
.antd-pro-containers-subscription-all-styles-priceTable tr th {
  font-weight: bold;
}

.antd-pro-containers-subscriber-all-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-subscriber-all-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-subscriber-all-styles-img {
  width: 80px;
  height: 80px;
}
.antd-pro-containers-subscriber-all-styles-successLabel {
  border: 1px solid #5cb85c;
  color: #54af54;
  padding: 1px;
  font-size: 11px;
  border-radius: 2px;
  font-weight: bold;
  width: 50px;
  text-align: center;
}
.antd-pro-containers-subscriber-all-styles-warningLabel {
  border: 1px solid #f0ad4e;
  color: #e3a145;
  padding: 1px;
  font-size: 11px;
  border-radius: 2px;
  font-weight: bold;
  width: 50px;
  text-align: center;
}
.antd-pro-containers-subscriber-all-styles-dangerLabel {
  border: 1px solid #d9534f;
  color: #cc4e4a;
  padding: 1px;
  font-size: 11px;
  border-radius: 2px;
  font-weight: bold;
  width: 50px;
  text-align: center;
}

.antd-pro-containers-subscriber-package-info-styles-packageInfo {
  border: 1px solid #e8e8e8;
  padding: 10px 20px;
}
.antd-pro-containers-subscriber-package-info-styles-packageInfo .antd-pro-containers-subscriber-package-info-styles-background1 {
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
  background: #f5f5f5;
}
.antd-pro-containers-subscriber-package-info-styles-packageInfo .antd-pro-containers-subscriber-package-info-styles-background2 {
  padding: 10px;
  border-bottom: 1px solid #e8e8e8;
  background: #fcf8e3;
}

.antd-pro-containers-payments-all-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-payments-all-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-payments-all-styles-img {
  width: 80px;
  height: 80px;
}
.antd-pro-containers-payments-all-styles-successLabel {
  border: 1px solid #5cb85c;
  color: #54af54;
  padding: 1px;
  font-size: 11px;
  border-radius: 2px;
  font-weight: bold;
  width: 50px;
  text-align: center;
}
.antd-pro-containers-payments-all-styles-warningLabel {
  border: 1px solid #f0ad4e;
  color: #e3a145;
  padding: 1px;
  font-size: 11px;
  border-radius: 2px;
  font-weight: bold;
  width: 50px;
  text-align: center;
}
.antd-pro-containers-payments-all-styles-dangerLabel {
  border: 1px solid #d9534f;
  color: #cc4e4a;
  padding: 1px;
  font-size: 11px;
  border-radius: 2px;
  font-weight: bold;
  width: 50px;
  text-align: center;
}

.antd-pro-containers-members-views-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-members-views-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-members-views-styles-img {
  width: 80px;
  height: 80px;
}
.antd-pro-containers-members-views-styles-filterData > div > div {
  justify-content: space-between !important;
  width: 300px;
}
.antd-pro-containers-members-views-styles-filterData > div > div > div {
  text-align: left;
}
.antd-pro-containers-members-views-styles-ant-table-thead .antd-pro-containers-members-views-styles-tr .antd-pro-containers-members-views-styles-th {
  text-align: center;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-containers-login-login-main {
  max-width: 368px;
  margin: 12% auto;
}
@media screen and (max-width: 576px) {
  .antd-pro-containers-login-login-main {
    width: 95%;
  }
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-icon {
  font-size: 24px;
  color: rgba(0, 0, 0, 0.2);
  margin-left: 16px;
  vertical-align: middle;
  cursor: pointer;
  transition: color 0.3s;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-icon:hover {
  color: #36c889;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-other {
  text-align: left;
  margin-top: 24px;
  line-height: 22px;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-other .antd-pro-containers-login-login-register {
  float: right;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-forgot {
  float: right;
  color: black;
}
.antd-pro-containers-login-login-main .antd-pro-containers-login-login-button {
  width: 100%;
}

.antd-pro-containers-article-views-styles-filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
}
.antd-pro-containers-article-views-styles-btn {
  margin-right: 0.4em;
}
.antd-pro-containers-article-views-styles-img {
  width: 80px;
  height: 80px;
}
.antd-pro-containers-article-views-styles-filterData > div > div {
  justify-content: space-between !important;
  width: 300px;
}
.antd-pro-containers-article-views-styles-filterData > div > div > div {
  text-align: left;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-exception-index-exception {
  display: flex;
  align-items: center;
  height: 80%;
  min-height: 500px;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
  flex: 0 0 62.5%;
  width: 62.5%;
  padding-right: 152px;
  zoom: 1;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock:before,
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock:after {
  content: ' ';
  display: table;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock:after {
  clear: both;
  visibility: hidden;
  font-size: 0;
  height: 0;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgEle {
  height: 360px;
  width: 100%;
  max-width: 430px;
  float: right;
  background-repeat: no-repeat;
  background-position: 50% 50%;
  background-size: contain;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content {
  flex: auto;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content h1 {
  color: #434e59;
  font-size: 72px;
  font-weight: 600;
  line-height: 72px;
  margin-bottom: 24px;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content .antd-pro-components-exception-index-desc {
  color: rgba(0, 0, 0, 0.45);
  font-size: 20px;
  line-height: 28px;
  margin-bottom: 16px;
}
.antd-pro-components-exception-index-exception .antd-pro-components-exception-index-content .antd-pro-components-exception-index-actions button:not(:last-child) {
  margin-right: 8px;
}
@media screen and (max-width: 1200px) {
  .antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
    padding-right: 88px;
  }
}
@media screen and (max-width: 576px) {
  .antd-pro-components-exception-index-exception {
    display: block;
    text-align: center;
  }
  .antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
    padding-right: 0;
    margin: 0 auto 24px;
  }
}
@media screen and (max-width: 480px) {
  .antd-pro-components-exception-index-exception .antd-pro-components-exception-index-imgBlock {
    margin-bottom: -24px;
    overflow: hidden;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-layouts-auth-layout-container {
  display: flex;
  flex-direction: column;
  height: 100vh;
  overflow: auto;
  background: #f0f2f5;
}
.antd-pro-layouts-auth-layout-lang {
  text-align: right;
  width: 100%;
  height: 40px;
  line-height: 44px;
}
.antd-pro-layouts-auth-layout-lang .ant-dropdown-trigger {
  margin-right: 24px;
}
.antd-pro-layouts-auth-layout-content {
  padding: 32px 0;
  flex: 1 1;
}
@media (min-width: 768px) {
  .antd-pro-layouts-auth-layout-container {
    background-repeat: no-repeat;
    background-position: center 110px;
    background-size: 100%;
  }
  .antd-pro-layouts-auth-layout-content {
    padding: 32px 0 24px 0;
  }
}
.antd-pro-layouts-auth-layout-top {
  text-align: center;
}
.antd-pro-layouts-auth-layout-header {
  height: 44px;
  line-height: 44px;
}
.antd-pro-layouts-auth-layout-header a {
  text-decoration: none;
}
.antd-pro-layouts-auth-layout-logo {
  height: 180px;
  vertical-align: top;
  margin-right: 16px;
}
.antd-pro-layouts-auth-layout-titleDiv {
  margin-top: 20px;
}
.antd-pro-layouts-auth-layout-title {
  font-size: 33px;
  color: rgba(0, 0, 0, 0.85);
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
  position: relative;
  top: 2px;
}
.antd-pro-layouts-auth-layout-desc {
  font-size: 14px;
  color: rgba(0, 0, 0, 0.45);
  margin-top: 12px;
  margin-bottom: 40px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-global-footer-index-globalFooter {
  padding: 0 16px;
  margin: 48px 0 24px 0;
  text-align: center;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links {
  margin-bottom: 8px;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links a {
  color: rgba(0, 0, 0, 0.45);
  transition: all 0.3s;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links a:not(:last-child) {
  margin-right: 40px;
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-links a:hover {
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-components-global-footer-index-globalFooter .antd-pro-components-global-footer-index-copyright {
  color: rgba(0, 0, 0, 0.45);
  font-size: 14px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-global-header-index-header {
  height: 64px;
  padding: 0;
  background: #fff;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.antd-pro-components-global-header-index-logo {
  height: 64px;
  line-height: 64px;
  vertical-align: top;
  display: inline-block;
  padding: 0 0 0 24px;
  cursor: pointer;
  font-size: 20px;
}
.antd-pro-components-global-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
}
.antd-pro-components-global-header-index-menu .anticon {
  margin-right: 8px;
}
.antd-pro-components-global-header-index-menu .ant-dropdown-menu-item {
  min-width: 160px;
}
.antd-pro-components-global-header-index-trigger {
  font-size: 20px;
  height: 64px;
  cursor: pointer;
  transition: all 0.3s, padding 0s;
  padding: calc((64px - 20px) / 2) 24px;
}
.antd-pro-components-global-header-index-trigger:hover {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right {
  float: right;
  height: 100%;
  overflow: hidden;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action {
  cursor: pointer;
  padding: 0 12px;
  display: inline-block;
  transition: all 0.3s;
  height: 100%;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action > i {
  vertical-align: middle;
  color: rgba(0, 0, 0, 0.65);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action:hover {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-action.opened {
  background: rgba(0, 0, 0, 0.025);
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-search {
  padding: 0 12px;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-search:hover {
  background: transparent;
}
.antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
  margin: calc((64px - 24px) / 2) 0;
  margin-right: 8px;
  color: #36c889;
  background: rgba(255, 255, 255, 0.85);
  vertical-align: top;
}
.antd-pro-components-global-header-index-dark {
  height: 64px;
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action > i {
  color: rgba(255, 255, 255, 0.85);
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action:hover,
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action.opened {
  background: #36c889;
}
.antd-pro-components-global-header-index-dark .antd-pro-components-global-header-index-action .ant-badge {
  color: rgba(255, 255, 255, 0.85);
}
@media only screen and (max-width: 768px) {
  .antd-pro-components-global-header-index-header .ant-divider-vertical {
    vertical-align: unset;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-name {
    display: none;
  }
  .antd-pro-components-global-header-index-header i.antd-pro-components-global-header-index-trigger {
    padding: 22px 12px;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-logo {
    padding-left: 12px;
    padding-right: 12px;
    position: relative;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-right {
    position: absolute;
    right: 12px;
    top: 0;
    background: #fff;
  }
  .antd-pro-components-global-header-index-header .antd-pro-components-global-header-index-right .antd-pro-components-global-header-index-account .antd-pro-components-global-header-index-avatar {
    margin-right: 0;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-header-dropdown-index-container > * {
  background-color: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
  border-radius: 4px;
}
@media screen and (max-width: 480px) {
  .antd-pro-components-header-dropdown-index-container {
    width: 100% !important;
  }
  .antd-pro-components-header-dropdown-index-container > * {
    border-radius: 0 !important;
  }
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-notice-icon-notice-list-list {
  max-height: 400px;
  overflow: auto;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item {
  transition: all 0.3s;
  overflow: hidden;
  cursor: pointer;
  padding-left: 24px;
  padding-right: 24px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-meta {
  width: 100%;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-avatar {
  background: #fff;
  margin-top: 4px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-iconElement {
  font-size: 32px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item.antd-pro-components-notice-icon-notice-list-read {
  opacity: 0.4;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item:last-child {
  border-bottom: 0;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item:hover {
  background: #f0fff6;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-title {
  font-weight: normal;
  margin-bottom: 8px;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-description {
  font-size: 12px;
  line-height: 1.5;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-datetime {
  font-size: 12px;
  margin-top: 4px;
  line-height: 1.5;
}
.antd-pro-components-notice-icon-notice-list-list .antd-pro-components-notice-icon-notice-list-item .antd-pro-components-notice-icon-notice-list-extra {
  float: right;
  color: rgba(0, 0, 0, 0.45);
  font-weight: normal;
  margin-right: 0;
  margin-top: -1.5px;
}
.antd-pro-components-notice-icon-notice-list-notFound {
  text-align: center;
  padding: 73px 0 88px 0;
  color: rgba(0, 0, 0, 0.45);
}
.antd-pro-components-notice-icon-notice-list-notFound img {
  display: inline-block;
  margin-bottom: 16px;
  height: 76px;
}
.antd-pro-components-notice-icon-notice-list-clear {
  height: 46px;
  line-height: 46px;
  text-align: center;
  color: rgba(0, 0, 0, 0.65);
  border-radius: 0 0 4px 4px;
  border-top: 1px solid #e8e8e8;
  transition: all 0.3s;
  cursor: pointer;
}
.antd-pro-components-notice-icon-notice-list-clear:hover {
  color: rgba(0, 0, 0, 0.85);
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-notice-icon-index-popover {
  width: 336px;
}
.antd-pro-components-notice-icon-index-noticeButton {
  cursor: pointer;
  display: inline-block;
  transition: all 0.3s;
}
.antd-pro-components-notice-icon-index-icon {
  padding: 4px;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-nav-scroll {
  text-align: center;
}
.antd-pro-components-notice-icon-index-tabs .ant-tabs-bar {
  margin-bottom: 4px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-header-search-index-headerSearch .anticon-search {
  cursor: pointer;
  font-size: 16px;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input {
  transition: width 0.3s, margin-left 0.3s;
  width: 0;
  background: transparent;
  border-radius: 0;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input .ant-select-selection {
  background: transparent;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input input {
  border: 0;
  padding-left: 0;
  padding-right: 0;
  box-shadow: none !important;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input,
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input:hover,
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input:focus {
  border-bottom: 1px solid #d9d9d9;
}
.antd-pro-components-header-search-index-headerSearch .antd-pro-components-header-search-index-input.antd-pro-components-header-search-index-show {
  width: 210px;
  margin-left: 8px;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-sider-menu-index-logo {
  height: 64px;
  position: relative;
  line-height: 64px;
  padding-left: 24px;
  transition: all 0.3s;
  background: #002140;
  overflow: hidden;
}
.antd-pro-components-sider-menu-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 50px;
}
.antd-pro-components-sider-menu-index-logo h1 {
  color: white;
  display: inline-block;
  vertical-align: middle;
  font-size: 20px;
  margin: 0 0 0 12px;
  font-family: Avenir, 'Helvetica Neue', Arial, Helvetica, sans-serif;
  font-weight: 600;
}
.antd-pro-components-sider-menu-index-sider {
  min-height: 100vh;
  box-shadow: 2px 0 6px rgba(0, 21, 41, 0.35);
  position: relative;
  z-index: 10;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-fixSiderbar {
  position: fixed;
  top: 0;
  left: 0;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-fixSiderbar .ant-menu-root {
  overflow-y: auto;
  height: calc(100vh - 64px);
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light {
  box-shadow: 2px 0 8px 0 rgba(29, 35, 41, 0.05);
  background-color: white;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .antd-pro-components-sider-menu-index-logo {
  background: white;
  box-shadow: 1px 1px 0 0 #e8e8e8;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .antd-pro-components-sider-menu-index-logo h1 {
  color: #36c889;
}
.antd-pro-components-sider-menu-index-sider.antd-pro-components-sider-menu-index-light .ant-menu-light {
  border-right-color: transparent;
}
.antd-pro-components-sider-menu-index-icon {
  width: 14px;
  margin-right: 10px;
}
.top-nav-menu li.ant-menu-item {
  height: 64px;
  line-height: 64px;
}
.drawer .drawer-content {
  background: #001529;
}
.ant-menu-inline-collapsed > .ant-menu-item .sider-menu-item-img + span,
.ant-menu-inline-collapsed > .ant-menu-item-group > .ant-menu-item-group-list > .ant-menu-item .sider-menu-item-img + span,
.ant-menu-inline-collapsed > .ant-menu-submenu > .ant-menu-submenu-title .sider-menu-item-img + span {
  max-width: 0;
  display: inline-block;
  opacity: 0;
}
.ant-menu-item .sider-menu-item-img + span,
.ant-menu-submenu-title .sider-menu-item-img + span {
  transition: opacity 0.3s cubic-bezier(0.645, 0.045, 0.355, 1), width 0.3s cubic-bezier(0.645, 0.045, 0.355, 1);
  opacity: 1;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-components-top-nav-header-index-head {
  width: 100%;
  transition: background 0.3s, width 0.2s;
  height: 64px;
  box-shadow: 0 1px 4px rgba(0, 21, 41, 0.08);
  position: relative;
}
.antd-pro-components-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal {
  line-height: 64px;
  height: 100%;
}
.antd-pro-components-top-nav-header-index-head .ant-menu-submenu.ant-menu-submenu-horizontal .ant-menu-submenu-title {
  height: 100%;
}
.antd-pro-components-top-nav-header-index-head.antd-pro-components-top-nav-header-index-light {
  background-color: #fff;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main {
  display: flex;
  height: 64px;
  padding-left: 24px;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main.antd-pro-components-top-nav-header-index-wide {
  max-width: 1200px;
  margin: auto;
  padding-left: 0;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main .antd-pro-components-top-nav-header-index-left {
  flex: 1 1;
  display: flex;
}
.antd-pro-components-top-nav-header-index-head .antd-pro-components-top-nav-header-index-main .antd-pro-components-top-nav-header-index-right {
  width: 324px;
}
.antd-pro-components-top-nav-header-index-logo {
  width: 165px;
  height: 64px;
  position: relative;
  line-height: 64px;
  transition: all 0.3s;
  overflow: hidden;
}
.antd-pro-components-top-nav-header-index-logo img {
  display: inline-block;
  vertical-align: middle;
  height: 32px;
}
.antd-pro-components-top-nav-header-index-logo h1 {
  color: #fff;
  display: inline-block;
  vertical-align: top;
  font-size: 16px;
  margin: 0 0 0 12px;
  font-weight: 400;
}
.antd-pro-components-top-nav-header-index-light h1 {
  color: #002140;
}
.antd-pro-components-top-nav-header-index-menu {
  border: none;
  height: 64px;
  line-height: 64px;
}

.antd-pro-layouts-header-fixedHeader {
  position: fixed;
  top: 0;
  right: 0;
  width: 100%;
  z-index: 9;
  transition: width 0.2s;
}

/* stylelint-disable at-rule-empty-line-before,at-rule-name-space-after,at-rule-no-unknown */
/* stylelint-disable no-duplicate-selectors */
/* stylelint-disable */
/* stylelint-disable declaration-bang-space-before,no-duplicate-selectors,string-no-newline */
.antd-pro-layouts-basic-layout-content {
  margin: 24px;
  padding-top: 64px;
}

.ant-drawer-body {
    padding: 0px !important;
}

.ql-snow .ql-editor h5 {
    font-size: 1.4em;
}

.priceTable .ant-table-thead > tr {
  background: #fafafa !important;
}
.priceTable tr td,
.priceTable tr th {
  padding: 5px 10px;
}
.priceTable tr th {
  font-weight: bold;
}
input[type='text'],
input[type='password'],
input[type='number'],
.ant-select-selection {
  height: 33px;
}
.ant-input,
.ant-input-number,
.ant-select-selection--single {
  height: 33px;
}
.inputBox {
  /*margin-top: 10px;
    margin-bottom: 10px;*/
  margin-bottom: 12px;
}
.inputBox .labelRow {
  display: inline-block;
  overflow: hidden;
  line-height: 39.9999px;
  white-space: nowrap;
  text-align: right;
  vertical-align: middle;
  padding: 0 10px;
}
.inputBox .labelRow .extra a {
  color: #000000d9;
  margin-left: 10px;
  text-decoration: underline;
}
.inputBox .labelNew {
  float: left;
  padding-left: 5px;
  color: #000000d9;
}
.inputBox .innerBox {
  position: relative;
  line-height: 40px;
  zoom: 1;
}
.inputBox .innerBox .ant-calendar-picker {
  width: 100%;
}
.inputBox .uniTypeCheckbox .ant-checkbox-group {
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;
}
.inputBox .uniTypeCheckbox .ant-checkbox-group label {
  width: 45%;
  margin-bottom: 5px;
}
.ant-select-selection--multiple {
  height: auto;
}
.ant-select {
  width: 100%;
}
.ant-input-number,
.ant-calendar-picker {
  width: 100%;
}
.ant-calendar-picker-input,
.ant-time-picker-input {
  width: 100%;
}
.inputForm .ant-form-item {
  margin-bottom: 12px;
}
.inputForm .ant-form-item label {
  float: left;
  padding-left: 5px;
}
.linkIcon {
  font-size: 15px;
  color: #001529;
  margin-left: 3px;
  margin-right: 3px;
}
.ql-editor {
  height: 150px;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-main {
  display: flex;
}
.antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-main .antd-pro-components-page-header-index-content {
  text-align: right;
}
.mt10 {
  margin-top: 10px;
}
.mt20 {
  margin-top: 20px;
}
.outerLoader {
  height: 100%;
  width: 100%;
  background: #ffffffc7;
  z-index: 9999;
  position: fixed;
  left: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  color: #001529;
  font-size: 25px;
}
.outerLoader .ant-spin-dot {
  font-size: 40px;
}
.outerLoader .mainLoader {
  margin-top: 20px;
  font-size: 50px;
}
.outerLoader .mainLoader i {
  margin-right: 20px;
}
.rowFlex {
  display: flex;
}
.rowFlex button {
  margin-left: 10px;
}
.rowFlex .d1 {
  width: 100%;
}
.rowFlex .d1.mr10 {
  margin-right: 10px;
}
.rowFlex .d2 {
  width: 100%;
  margin-left: 10px;
}
.rowFlex .linkBtn {
  margin-top: 5px;
}
.linkBtn {
  line-height: 1.499;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  touch-action: manipulation;
  height: 32px;
  padding: 0 15px;
  font-size: 14px;
  border-radius: 4px;
  display: flex;
  justify-content: center;
  align-items: center;
  border: 1px solid #d9d9d9;
  color: rgba(0, 0, 0, 0.65);
  margin-left: 10px;
}
.ant-table-thead tr th {
  background-color: #f8f9fa !important;
}
.ant-table-small > .ant-table-content > .ant-table-body {
  margin: 0 !important;
}
.ant-form input[type='file'] {
  display: block;
  height: 33px;
  margin: 3px 0;
}
.roundSmallBtn {
  margin-bottom: 5px;
  font-size: 12px;
  padding: 2px 10px;
  height: auto;
  border-radius: 50px;
  width: 125px !important;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.65) !important;
  position: relative;
  display: inline-block;
  font-weight: 400;
  white-space: nowrap;
  text-align: center;
  border: 1px solid #ddd;
}
.roundSmallBtn:hover {
  text-decoration: none !important;
}
.roundSmallBtn2 {
  margin-bottom: 5px;
  font-size: 12px;
  padding: 2px 10px;
  height: auto;
  border-radius: 50px;
  width: 95px !important;
  margin-right: 5px;
  color: rgba(0, 0, 0, 0.65) !important;
  position: relative;
  display: inline-block;
  font-weight: 400;
  text-align: center;
  border: 1px solid #ddd;
}
.roundSmallBtn2:hover {
  text-decoration: none !important;
}
.notificationBox {
  width: 500px !important;
}
.notificationBox .ant-modal-confirm-body {
  text-align: center;
}
.notificationBox .ant-modal-confirm-body .anticon {
  font-size: 80px;
  float: inherit;
}
.notificationBox .ant-modal-confirm-body .ant-modal-confirm-content {
  margin-left: 0 !important;
  margin-top: 20px;
}
.notificationBox .ant-modal-confirm-body .ant-modal-confirm-content .title {
  font-size: 22px;
  font-weight: bold;
  margin-bottom: 10px;
}
.notificationBox .ant-modal-confirm-body .ant-modal-confirm-content .message {
  font-size: 16px;
}
.notificationBox .ant-modal-confirm-btns {
  float: left;
  width: 100%;
  text-align: center;
}
.notificationBox .ant-modal-confirm-btns .ant-btn {
  width: 100px;
}
.cke_autocomplete_panel {
  border: none !important;
  background-color: white;
  box-shadow: 0 0 5px gray;
}
.cke_autocomplete_panel > li {
  padding: 7px 10px;
  font-size: 12px;
  background-color: #FFF;
  border-bottom: 1px solid #f5f5f5;
}
.marginH {
  margin-left: 5px;
  margin-right: 5px;
}
.tableBox.striped .tableRow:nth-child(odd) {
  background-color: #f9f9f9;
}
.tableBox .tableRow {
  padding: 6px 10px;
  display: flex;
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-Right: 1px solid #ddd;
}
.tableBox .tableRow .title {
  font-weight: bold;
  width: 25%;
}
.tableBox .tableRow p {
  margin-bottom: 0;
}
.tableBox .tableRow:first-child {
  border-top: 1px solid #ddd;
}
.ant-collapse-header {
  padding: 8px 16px;
}
.ant-collapse-header .ant-collapse-extra {
  position: relative;
  bottom: 2px;
}
.ant-collapse-header .ant-collapse-extra .ant-btn-sm span {
  text-transform: capitalize !important;
}
.articleInfoBox .ant-row {
  border-bottom: 1px solid #ddd;
  border-left: 1px solid #ddd;
  border-Right: 1px solid #ddd;
}
.articleInfoBox .ant-row:first-child {
  border-top: 1px solid #ddd;
}
.articleInfoBox .ant-row:nth-child(odd) {
  background-color: #f9f9f9;
}
.articleInfoBox .row {
  display: flex;
  padding: 6px 10px;
}
.articleInfoBox .row .title {
  width: 30%;
  font-weight: bold;
}
.customBox {
  margin-top: 10px;
}
.customBox .customTitle {
  font-size: 15px;
  padding: 10px 8px;
  font-weight: 600;
  border: 1px solid #ddd;
  border-bottom: none;
  background: #e7e9eb8c;
}
.ant-collapse-item {
  border-radius: 0 !important;
}
.ant-collapse-item .ant-collapse-header {
  background: #5fd1c7 !important;
  border-radius: 0 !important;
}
.confirmMessage {
  font-size: 18px;
  color: red;
}
.chooseEmailModal .ant-modal-confirm-btns {
  display: none;
}
.alignRight {
  text-align: right;
}
.ant-btn-primary {
  background-color: #001529;
  border-color: #001529;
}
.ant-btn-primary:hover {
  background-color: #001529;
}
.ant-btn-danger {
  color: #fff;
  background-color: #d9534f;
  border-color: #d43f3a;
}
.ant-btn-basic {
  color: #333;
  background-color: #f1f1f1;
  border-color: #f7ebeb;
}
.ant-success {
  color: #5cb85c;
}
.ant-info {
  color: #5bc0de;
}
.ant-btn-warning {
  color: #fff !important;
  background-color: #f0ad4e !important;
  border-color: #eea236 !important;
}
.ant-btn-success {
  width: 150px;
  background: #1556bc;
  font-size: 13px;
  text-transform: uppercase;
  color: #fff;
  margin: 25px 0 0;
  border-radius: 50px;
  font-weight: 600;
  height: 42px !important;
}
.ant-btn-info {
  color: #fff;
  background-color: #5bc0de;
  border-color: #46b8da;
}
.ant-btn-cancel {
  background: #999;
  color: #fff;
  font-size: 14px;
  text-transform: capitalize;
  font-weight: 500;
  border-radius: 50px;
  min-width: 100px;
  margin-right: 15px;
  height: 42px !important;
}
.ant-warning {
  color: #f0ad4e;
}
.ant-table-thead tr th {
  background-color: #001529 !important;
  color: white;
  border-right-color: rgba(52, 73, 94, 0.94) !important;
}
.ant-drawer-header {
  background-color: #EDEDED !important;
}
.ant-collapse {
  margin-bottom: 20px;
}
a[href] {
  color: #551a8b;
}
a[href]:hover {
  text-decoration: underline;
}
.label {
  display: inline;
  font-size: 12px;
  font-weight: 500;
  line-height: 1;
  color: #fff;
  text-align: center;
  white-space: nowrap;
  vertical-align: baseline;
  border-radius: 0.25em;
  padding: 0.2em 0.6em 0.2em;
}
.label.sm {
  padding: 0.1em 0.6em 0.2em;
}
.label-success {
  background-color: #5cb85c;
}
.label-primary {
  background-color: #001529;
}
.label-warning {
  background-color: #f0ad4e;
}
.label-tag {
  border: none;
  font-size: 12px !important;
  color: black;
  text-transform: capitalize;
}
.extraBoxCls {
  position: absolute;
  top: 10px;
  right: 2px;
}
.customDis.ant-collapse-item-disabled .ant-collapse-header {
  color: black !important;
  cursor: pointer;
}
.authorList {
  padding: 0 8px;
}
.authorList .ant-row {
  border: 1px solid #ddd;
}
.authorList .ant-row:first-child {
  background-color: #001529;
}
.authorList .ant-row:first-child .ant-col {
  color: white;
}
.authorList .ant-row:not(:first-child) {
  border-top: none;
}
.authorList .ant-row .ant-col {
  padding: 5px 10px;
}
.authorList .ant-row .ant-col.ant-col-20 {
  border-right: 1px solid #ddd;
}
.authorList .ant-row .ant-col.ant-col-4 {
  display: flex;
  justify-content: center;
  align-items: center;
}
.authorList .ant-row .ant-col.ant-col-4 .ant-btn {
  margin-top: 20px;
}
.ant-menu-inline-collapsed-tooltip .ant-tooltip-inner a span {
  color: white !important;
}
.dashboardCard {
  background: white;
  padding: 10px 30px;
  border-radius: 5px;
}
.dashboardCard .statusRow {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
}
.dashboardCard .statusRow a {
  color: #666;
}
.dashboardCard .statusRow .statusColumn {
  margin-bottom: 10px;
}
.dashboardCard .statusRow .statusColumn:hover .ant-card-body {
  background: #f4f4f4;
}
.dashboardCard .statusRow .statusColumn:hover .ant-card-body a {
  color: #001529;
}
.dashboardCard .statusRow .statusColumn .ant-card-body {
  padding: 10px;
}
.dashboardCard .statusRow .box {
  height: 100px;
  text-align: center;
}
.dashboardCard .statusRow .box.active {
  color: white;
  background-color: #5cb85c;
}
.dashboardCard .statusRow .box img {
  height: 20px;
}
.dashboardCard .statusRow .status {
  font-size: 12px;
}
.dashboardCard .statusRow .title {
  font-size: 14px;
}
.dashboardCard .statusRow .iconDiv {
  font-size: 20px;
  padding: 1px;
}
.date {
  font-size: 13px;
}
.statusRow .statusColumn {
  margin-bottom: 10px;
}
.statusRow .statusColumn .ant-card-body {
  padding: 5px 3px;
}
.statusRow .box {
  height: 100px;
  text-align: center;
}
.statusRow .box.active {
  color: white;
  background-color: #5cb85c;
}
.statusRow .box img {
  height: 20px;
  margin-bottom: 5px;
}
.statusRow .status {
  font-size: 12px;
}
.statusRow .title {
  font-size: 14px;
}
.statusRow .iconDiv {
  font-size: 20px;
  padding: 1px;
}
.drawerFooter {
  position: absolute;
  right: 0;
  bottom: 0;
  width: 100%;
  border-top: 1px solid #e9e9e9;
  padding: 10px 15px;
  background: #fff;
  text-align: right;
}
.searchBox {
  cursor: pointer;
  display: inline-flex;
  width: 40%;
  position: relative;
  align-items: center;
  top: 9px;
}
.searchBox .ant-input {
  border-top-left-radius: 50px;
  border-bottom-left-radius: 50px;
}
.searchBox .ant-input-group-addon {
  border-top-right-radius: 50px;
  border-bottom-right-radius: 50px;
  padding: 0;
}
.searchBox .ant-input-group-addon a {
  padding: 7px 15px !important;
}
.sticky-header {
  width: 100% !important;
  border-left: 1px solid white;
}
.sticky-header .sticky-header-bg {
  background-color: #001529;
  color: white;
}
.sticky-header .sticky-header-bg .displaySearch {
  display: block !important;
}
.sticky-header .sticky-header-bg .titleDiv {
  font-size: 20px;
  font-weight: 600;
  min-width: 50%;
}
.sticky-header .sticky-header-bg .titleDiv .searchBox {
  display: block;
  width: 80%;
  margin-bottom: 10px;
}
.fixed {
  transition: all 0.2s ease;
  position: fixed;
  top: 0;
  left: 256px;
  width: 82.5% !important;
  z-index: 9;
}
.fixed .headerPanel {
  padding: 20px;
}
.fixedColl {
  left: 82px !important;
  width: 94.5% !important;
  transition: all 0.2s ease;
  position: fixed;
  top: 0;
  z-index: 9;
}
.fixedColl .headerPanel {
  padding: 20px;
}
.headerPanel {
  display: flex;
  justify-content: space-between !important;
  background: white;
  align-items: center;
}
.headerPanel .searchBox {
  top: 0 !important;
  display: none;
}
.headerPanel .titleDiv {
  font-size: 18px;
}
.scheduleDrawer .ck-editor__editable {
  min-height: 250px !important;
}
.GuideLineDrawer .ck-editor__editable {
  min-height: 350px !important;
}
.dotsReplace {
  display: -webkit-box;
  max-width: 65%;
  height: 42px;
  margin: 0px auto;
  font-size: 14px;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.readMoreBtn {
  display: -webkit-box;
  max-width: 65%;
  height: 20px;
  margin: 0px auto;
  font-size: 14px;
  line-height: 1.5;
  -webkit-line-clamp: 2;
  -webkit-box-orient: vertical;
  overflow: hidden;
  text-overflow: ellipsis;
}
.wrapRow {
  display: flex;
  flex-wrap: wrap;
}
.mr-5 {
  margin-right: 5px;
}
.ml-5 {
  margin-left: 5px;
}
.ant-btn-sm {
  height: 24px;
  padding: 2px 10px !important;
  font-size: 14px;
  border-radius: 3px !important;
}
.cke_inner {
  display: none !important;
}
.innerBox .cke_inner {
  display: block !important;
}
.ql-container.ql-snow .ql-editor {
  max-height: 100px;
}
.ant-table-thead tr th {
  text-align: center;
}
.btnGroup button {
  margin-left: 10px;
  margin-bottom: 5px;
}
.lgCk .cke_contents {
  height: 700px !important;
}
.antd-pro-layouts-auth-layout-header a:hover {
  text-decoration: none !important;
}
.ckBox .cke_contents {
  height: 600px !important;
}
.mb10 {
  margin-bottom: 10px;
}
.ant-form-item-label {
  text-align: left;
}
.wrapBox {
  display: flex;
  flex-wrap: wrap;
}
.wrapBox .widthLabel {
  font-weight: bold;
  text-decoration: underline;
  color: black;
}
.wrapBox .widthLabel.w100 {
  width: 100%;
}
.wrapBox .widthLabel.w200 {
  width: 200%;
}
.wrapBox label {
  font-weight: 600 !important;
}

