@primary: #001529;
@secondary: #5bd49b;
@info: #5bc0de;
@warning: #f0ad4e;
@danger: #d9534f;
@basic: #f1f1f1;
@grey: #acacac;
@gray: #5f5f5f;
@darkGray: #4d4d4d;
@lightGray: #9b9b9b;
@fontColor: #495057;
@lightBg: #E7E9EB;
@success: #5cb85c;

:global {
  .priceTable {
    .ant-table-thead > tr {
      background: #fafafa !important;
    }

    tr {
      td, th {
        padding: 5px 10px;
      }

      th {
        font-weight: bold;
      }
    }
  }

  input[type='text'], input[type='password'], input[type='number'], .ant-select-selection {
    height: 33px;
  }

  .ant-input, .ant-input-number, .ant-select-selection--single {
    height: 33px;
  }

  .inputBox {
    /*margin-top: 10px;
    margin-bottom: 10px;*/

    margin-bottom: 12px;

    .labelRow {
      display: inline-block;
      overflow: hidden;
      line-height: 39.9999px;
      white-space: nowrap;
      text-align: right;
      vertical-align: middle;
      padding: 0 10px;

      .extra {
        a {
          color: #000000d9;
          margin-left: 10px;
          text-decoration: underline;
        }
      }
    }

    .labelNew {
      //line-height: 25px;
      float: left;
      padding-left: 5px;
      color: #000000d9;
    }

    .innerBox {
      //margin-top: 14px;
      position: relative;
      line-height: 40px;
      zoom: 1;
      //padding: 3px 0 2px;

      &.logoCls {
        //margin-top: 10px;
      }

      .ant-calendar-picker {
        width: 100%;
      }
    }

    .uniTypeCheckbox {
      .ant-checkbox-group {
        display: flex;
        flex-wrap: wrap;
        flex-direction: row;

        label {
          width: 45%;
          margin-bottom: 5px;
        }
      }
    }
  }

  .ant-select-selection--multiple {
    height: auto;
  }

  .ant-select {
    width: 100%;
  }

  .ant-input-number, .ant-calendar-picker {
    width: 100%;
  }

  .ant-calendar-picker-input, .ant-time-picker-input {
    width: 100%;
  }

  .inputForm {
    .ant-form-item {
      margin-bottom: 12px;

      label {
        float: left;
        padding-left: 5px;
      }
    }
  }

  .linkIcon {
    font-size: 15px;
    color: @primary;
    margin-left: 3px;
    margin-right: 3px;
  }

  .ql-editor {
    height: 150px;
  }

  .antd-pro-components-page-header-index-pageHeader .antd-pro-components-page-header-index-main {
    display: flex;

    .antd-pro-components-page-header-index-content {
      text-align: right;
    }
  }

  .mt10 {
    margin-top: 10px;
  }

  .mt20 {
    margin-top: 20px;
  }

  .outerLoader {
    height: 100%;
    width: 100%;
    background: #ffffffc7;
    z-index: 9999;
    position: fixed;
    left: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    color: @primary;
    font-size: 25px;

    .ant-spin-dot {
      font-size: 40px;
    }

    .mainLoader {
      margin-top: 20px;
      font-size: 50px;

      i {
        margin-right: 20px;
      }
    }
  }

  .rowFlex {
    display: flex;

    button {
      margin-left: 10px;
    }

    .d1 {
      width: 100%;

      &.mr10 {
        margin-right: 10px;
      }
    }

    .d2 {
      width: 100%;
      margin-left: 10px;
    }

    .linkBtn {
      margin-top: 5px;
    }
  }

  .linkBtn {
    line-height: 1.499;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    touch-action: manipulation;
    height: 32px;
    padding: 0 15px;
    font-size: 14px;
    border-radius: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    border: 1px solid #d9d9d9;
    color: rgba(0, 0, 0, 0.65);
    margin-left: 10px;
  }

  .ant-table-thead {
    tr {
      th {
        background-color: #f8f9fa !important;
      }
    }
  }

  .ant-table-small > .ant-table-content > .ant-table-body {
    margin: 0 !important;
  }

  .ant-form input[type='file'] {
    display: block;
    height: 33px;
    margin: 3px 0;
  }

  .roundSmallBtn {
    &:hover {
      text-decoration: none !important;
    }

    margin-bottom: 5px;
    font-size: 12px;
    padding: 2px 10px;
    height: auto;
    border-radius: 50px;
    width: 125px !important;
    margin-right: 5px;
    color: rgba(0, 0, 0, 0.65) !important;
    position: relative;
    display: inline-block;
    font-weight: 400;
    white-space: nowrap;
    text-align: center;
    border: 1px solid #ddd;
  }

  .roundSmallBtn2 {
    &:hover {
      text-decoration: none !important;
    }

    margin-bottom: 5px;
    font-size: 12px;
    padding: 2px 10px;
    height: auto;
    border-radius: 50px;
    width: 95px !important;
    margin-right: 5px;
    color: rgba(0, 0, 0, 0.65) !important;
    position: relative;
    display: inline-block;
    font-weight: 400;
    //white-space: nowrap;
    text-align: center;
    border: 1px solid #ddd;
  }

  .notificationBox {
    width: 500px !important;

    .ant-modal-confirm-body {
      text-align: center;

      .anticon {
        font-size: 80px;
        float: inherit;
      }

      .ant-modal-confirm-content {
        //margin-left: 50px;
        margin-left: 0 !important;
        margin-top: 20px;

        .title {
          font-size: 22px;
          font-weight: bold;
          margin-bottom: 10px;
        }

        .message {
          font-size: 16px;
        }
      }
    }

    .ant-modal-confirm-btns {
      float: left;
      width: 100%;
      text-align: center;

      .ant-btn {
        width: 100px;
      }
    }
  }

  .cke_autocomplete_panel {
    border: none !important;
    background-color: white;
    box-shadow: 0 0 5px gray;

    > li {
      padding: 7px 10px;
      font-size: 12px;
      background-color: #FFF;
      border-bottom: 1px solid #f5f5f5;
    }
  }

  .marginH {
    margin-left: 5px;
    margin-right: 5px;
  }


  .tableBox {
    &.striped {
      .tableRow {

        &:nth-child(odd) {
          background-color: #f9f9f9;
        }
      }

    }

    .tableRow {
      padding: 6px 10px;
      display: flex;

      .title {
        font-weight: bold;
        width: 25%;
      }

      p {
        margin-bottom: 0;
      }

      &:first-child {
        border-top: 1px solid #ddd;
      }


      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;
      border-Right: 1px solid #ddd;


    }
  }

  .ant-collapse-header {
    padding: 8px 16px;

    .ant-collapse-extra {
      position: relative;
      bottom: 2px;

      .ant-btn-sm {
        span {
          text-transform: capitalize !important;
        }
      }
    }
  }

  .articleInfoBox {
    .ant-row {
      &:first-child {
        border-top: 1px solid #ddd;
      }

      &:nth-child(odd) {
        background-color: #f9f9f9;
      }

      border-bottom: 1px solid #ddd;
      border-left: 1px solid #ddd;
      border-Right: 1px solid #ddd;
    }

    .row {
      display: flex;
      padding: 6px 10px;

      .title {
        width: 30%;
        font-weight: bold;
      }

    }

  }

  .customBox {
    margin-top: 10px;

    .customTitle {
      font-size: 15px;
      padding: 10px 8px;
      font-weight: 600;
      border: 1px solid #ddd;
      border-bottom: none;
      background: #e7e9eb8c;
    }
  }

  .ant-collapse-item {
    border-radius: 0 !important;

    .ant-collapse-header {
      //background: @lightBg !important;
      background: #5fd1c7 !important;
      border-radius: 0 !important;
    }
  }

  .confirmMessage {
    font-size: 18px;
    color: red;
  }

  .chooseEmailModal {
    .ant-modal-confirm-btns {
      display: none;
    }
  }

  .alignRight {
    text-align: right;
  }

  .ant-btn-primary {
    background-color: @primary;
    border-color: @primary;

    &:hover {
      background-color: @primary;
    }
  }


  .ant-btn-danger {
    color: #fff;
    background-color: @danger;
    border-color: #d43f3a;
  }

  .ant-btn-basic {
    color: #333;
    background-color: @basic;
    border-color: #f7ebeb;
  }

  .ant-success {
    color: @success;
  }

  .ant-info {
    color: @info;
  }

  .ant-btn-warning {
    color: #fff !important;
    background-color: @warning !important;
    border-color: #eea236 !important;
  }

  .ant-btn-success {
    width: 150px;
    background: #1556bc;
    font-size: 13px;
    text-transform: uppercase;
    color: #fff;
    margin: 25px 0 0;
    border-radius: 50px;
    font-weight: 600;
    height: 42px !important;
  }

  .ant-btn-info {
    color: #fff;
    background-color: @info;
    border-color: #46b8da;
  }

  .ant-btn-cancel {
    background: #999;
    color: #fff;
    font-size: 14px;
    text-transform: capitalize;
    font-weight: 500;
    border-radius: 50px;
    min-width: 100px;
    margin-right: 15px;
    height: 42px !important;
  }

  .ant-warning {
    color: @warning;
  }

  .ant-table-thead {
    tr {
      th {
        background-color: @primary !important;
        color: white;
        border-right-color: rgba(52, 73, 94, .94) !important;
      }
    }
  }

  .ant-drawer-header {
    background-color: #EDEDED !important;

    .ant-drawer-title {
      //color: white;
    }
  }

  .ant-drawer-content-wrapper {
    //box-shadow: 0 0 20px white!important;
  }

  .ant-collapse {
    margin-bottom: 20px;
  }

  a {
    &[href] {
      color: rgb(85, 26, 139);

      &:hover {
        text-decoration: underline;
      }
    }
  }

  .label {
    display: inline;
    font-size: 12px;
    font-weight: 500;
    line-height: 1;
    color: #fff;
    text-align: center;
    white-space: nowrap;
    vertical-align: baseline;
    border-radius: .25em;
    padding: .2em .6em .2em;

    &.sm {
      padding: .1em .6em .2em;
    }

  }

  .label-success {
    background-color: @success;
  }

  .label-primary {
    background-color: @primary;
  }

  .label-warning {
    background-color: @warning;
  }

  .label-tag {
    border: none;
    font-size: 12px !important;
    //background: #ddffff !important;
    color: black;
    text-transform: capitalize;
  }

  .extraBoxCls {
    position: absolute;
    top: 10px;
    right: 2px;
  }

  .customDis {
    &.ant-collapse-item-disabled {
      .ant-collapse-header {
        color: black !important;
        cursor: pointer;
      }
    }
  }

  .authorList {
    padding: 0 8px;

    .ant-row {
      border: 1px solid #ddd;

      &:first-child {
        background-color: @primary;

        .ant-col {
          color: white;
        }
      }

      &:not(:first-child) {
        border-top: none;
      }

      .ant-col {
        padding: 5px 10px;

        &.ant-col-20 {
          border-right: 1px solid #ddd;
        }

        &.ant-col-4 {
          display: flex;
          justify-content: center;
          align-items: center;

          .ant-btn {
            margin-top: 20px;
          }
        }
      }

    }

  }


  .ant-menu-inline-collapsed-tooltip {
    .ant-tooltip-inner {
      a {
        span {
          color: white !important;
        }
      }
    }
  }

  .dashboardCard {
    background: white;
    padding: 10px 30px;
    border-radius: 5px;

    .statusRow {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      a {
        color: #666;
      }

      .statusColumn {
        margin-bottom: 10px;

        &:hover {
          .ant-card-body {
            background: #f4f4f4;

            a {
              color: @primary;
            }

            //box-shadow: inset 0 0 10px @lightGray;
          }
        }

        .ant-card-body {
          padding: 10px;
        }
      }

      .box {
        height: 100px;
        text-align: center;
        //text-transform: uppercase;
        &.active {
          color: white;
          background-color: @success;
        }

        img {
          height: 20px;
        }
      }

      .status {
        font-size: 12px;
      }

      .title {
        font-size: 14px;
      }

      .iconDiv {
        font-size: 20px;
        padding: 1px;
      }
    }

  }

  .date {
    font-size: 13px;
  }

  .statusRow {
    .statusColumn {
      margin-bottom: 10px;

      .ant-card-body {
        padding: 5px 3px;
      }
    }

    .box {
      height: 100px;
      text-align: center;
      //text-transform: uppercase;
      &.active {
        color: white;
        background-color: @success;
      }

      img {
        height: 20px;
        margin-bottom: 5px;
      }
    }

    .status {
      font-size: 12px;
    }

    .title {
      font-size: 14px;
    }

    .iconDiv {
      font-size: 20px;
      padding: 1px;
    }
  }

  .drawerFooter {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 100%;
    border-top: 1px solid #e9e9e9;
    padding: 10px 15px;
    background: #fff;
    text-align: right;
  }

  .searchBox {
    //height: 64px;
    cursor: pointer;
    display: inline-flex;
    width: 40%;
    position: relative;
    align-items: center;
    top: 9px;

    .ant-input {
      border-top-left-radius: 50px;
      border-bottom-left-radius: 50px;
    }

    .ant-input-group-addon {
      border-top-right-radius: 50px;
      border-bottom-right-radius: 50px;
      padding: 0;

      a {
        padding: 7px 15px !important;
      }
    }
  }


  .sticky-header {
    width: 100% !important;
    //height: 50px;
    border-left: 1px solid white;

    .sticky-header-bg {
      //background-color: #f5fafe;
      background-color: @primary;
      color: white;

      .displaySearch {
        display: block !important;
      }

      .titleDiv {
        font-size: 20px;
        font-weight: 600;
        min-width: 50%;

        .searchBox {
          display: block;
          width: 80%;
          margin-bottom: 10px;
        }
      }
    }
  }

  .fixed {
    transition: all .2s ease;
    position: fixed;
    top: 0;
    left: 256px;
    width: 82.5% !important;
    z-index: 9;

    .headerPanel {
      padding: 20px;
    }
  }

  .fixedColl {
    left: 82px !important;
    width: 94.5% !important;
    transition: all .2s ease;
    position: fixed;
    top: 0;
    z-index: 9;

    .headerPanel {
      padding: 20px;
    }
  }

  .headerPanel {
    display: flex;
    justify-content: space-between !important;
    background: white;
    align-items: center;

    .searchBox {
      top: 0 !important;
      display: none;
    }

    .titleDiv {
      font-size: 18px;
    }
  }

  .scheduleDrawer {
    .ck-editor__editable {
      min-height: 250px !important;
    }
  }

  .GuideLineDrawer {
    .ck-editor__editable {
      min-height: 350px !important;
    }
  }

  //.dotsReplace {
  //  display: block;
  //  white-space: nowrap;
  //  //width: 200px;
  //  overflow-y: hidden;
  //  text-overflow: ellipsis;
  //  height: 30px;
  //  -webkit-line-clamp: 1;
  //  -webkit-box-orient: vertical;
  //}
  .dotsReplace {
    display: -webkit-box;
    max-width: 65%;
    height: 42px;
    margin: 0px auto;
    font-size: 14px;
    line-height: 1.5;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .readMoreBtn {
    display: -webkit-box;
    max-width: 65%;
    height: 20px;
    margin: 0px auto;
    font-size: 14px;
    line-height: 1.5;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    overflow: hidden;
    text-overflow: ellipsis;
  }

  .wrapRow {
    display: flex;
    -webkit-flex-wrap: wrap;
    flex-wrap: wrap;
  }

  .mr-5 {
    margin-right: 5px;
  }

  .ml-5 {
    margin-left: 5px;
  }

  .ant-btn-sm {
    height: 24px;
    padding: 2px 10px !important;
    font-size: 14px;
    border-radius: 3px !important;
  }

  .cke_inner {
    display: none !important;
  }

  .innerBox {

    .cke_inner {
      display: block !important;
    }
  }

  .ql-container.ql-snow .ql-editor {
    max-height: 100px;
  }

  .ant-table-thead tr th {
    text-align: center;
  }

  .btnGroup {
    button {
      margin-left: 10px;
      margin-bottom: 5px;
    }
  }

  .lgCk {
    .cke_contents {
      height: 700px !important;
    }
  }

  .antd-pro-layouts-auth-layout-header {
    a {
      &:hover {
        text-decoration: none !important;
      }
    }
  }

  .ckBox {
    .cke_contents {
      height: 600px !important;
    }
  }

  .mb10 {
    margin-bottom: 10px;
  }

  .ant-form-item-label {
    text-align: left;
  }

  .wrapBox {
    display: flex;
    flex-wrap: wrap;

    .widthLabel {
      font-weight: bold;
      text-decoration: underline;
      color: black;

      &.w100 {
        width: 100%;
      }

      &.w200 {
        width: 200%;
      }
    }

    label {
      font-weight: 600 !important;
    }
  }

}

@primary-color: #36c889;