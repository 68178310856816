.packageInfo {
  border: 1px solid #e8e8e8;
  padding: 10px 20px;

  .background1 {
    padding: 10px;
    border-bottom: 1px solid #e8e8e8;
    background: #f5f5f5;

  }

  .background2 {
    padding: 10px;
    border-bottom: 1px solid #e8e8e8;
    background: #fcf8e3;

  }
}



@primary-color: #36c889;