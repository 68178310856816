.filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}

.btn {
  margin-right: 0.4em
}

.img {
  width: 80px;
  height: 80px;
}

.successLabel {
  border: 1px solid #5cb85c;
  color: #54af54;
  padding: 1px;
  font-size: 11px;
  border-radius: 2px;
  font-weight: bold;
  width: 50px;
  text-align: center;
}

.warningLabel {
  border: 1px solid #f0ad4e;
  color: #e3a145;
  padding: 1px;
  font-size: 11px;
  border-radius: 2px;
  font-weight: bold;
  width: 50px;
  text-align: center;
}

.dangerLabel {
  border: 1px solid #d9534f;
  color: #cc4e4a;
  padding: 1px;
  font-size: 11px;
  border-radius: 2px;
  font-weight: bold;
  width: 50px;
  text-align: center;
}

@primary-color: #36c889;