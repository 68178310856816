.filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}

.btn {
  margin-right: 0.4em
}

.img {
  width: 80px;
  height: 80px;
}

.filterData {

  > div > div {
    justify-content: space-between !important;
    width: 300px;

    > div {
      text-align: left;
    }
  }
}

@primary-color: #36c889;