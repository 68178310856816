.filterDropdown {
  padding: 8px;
  border-radius: 4px;
  background: #fff;
  box-shadow: 0 2px 8px rgba(0, 0, 0, .15);
}

.btn {
  margin-right: 0.4em
}

.img {
  width: 80px;
  height: 80px;
}

.flexRow {
  display: flex;
  justify-content: space-between;
}

.filterDiv {
  > div {
    display: inline-flex !important;
  }

  > div > div {
    /* display: inline-flex !important;
    justify-content: space-between !important;
    width: 400px; */
    width: 400px;
  }
}

.priceTable {
  tr {
    td, th {
      padding: 5px 10px;
    }

    th {
      font-weight: bold;
    }
  }
}

@primary-color: #36c889;